import { useMutation } from '@apollo/client'
import { Alert, Typography, Box, Card, CardContent, Collapse, FormHelperText, Grid, IconButton, TextField, useTheme } from '@mui/material'
import { navigate } from '@reach/router'
import React, { useEffect } from 'react'
import { Button, Icon, Logo } from 'src/templates'
import { FORGOT_PASSWORD, RESET_PASSWORD } from 'src/utils/queries'
import Validations from 'src/utils/validations'
import PasswordCheckup from 'src/components/common/PasswordCheckup'

const validations = new Validations()

const ForgotPassword = (props) => {

 const theme = useTheme()

 const styles = {
  root: {
   marginTop: "96px",
   background: `${theme.palette.primary.main}20`,
   height: '75vh',
   position: 'relative',
   minHeight: "558px",
   [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: '64px 0px 36px',
    marginTop: "96px",
   },
  },
  titleContainer: {
   paddingRight: '30%',
   [theme.breakpoints.down('lg')]: {
    paddingRight: '15%',
   },
   [theme.breakpoints.down('md')]: {
    padding: "0px 0px 48px 0px",
    textAlign: "center"
   },
  },
  cumploIcon: {
   background: theme.palette.text.secondary,
   borderRadius: '100%',
   margin: '0 auto',
   position: 'relative',
   width: '60px',
   height: '60px',
   zIndex: 3,
   boxShadow: '0px 2px 10px #00000029',
   display: 'flex',
   justifyContent: "center",
   alignItems: "center",
  },
  registerCard: {
   position: 'relative',
   top: '-33px',
   boxShadow: "0px 4px 4px rgb(0 0 0 / 10%)",
   borderRadius: "20px",
   width: '100%',
   margin: '0 auto',
   paddingTop: "24px",
   [theme.breakpoints.down('md')]: {
    width: '100%',
   },
   zIndex: 2,
  },
  //   OLD
  formGroupTitle: {
   paddingTop: '30px',
   paddingBottom: '6px',
   fontSize: '12px',
   fontWeight: 'bold',
   color: '#3CBA68',
   fontFamily: 'Lato',
  },
  disclaimer: {
   lineHeight: '15px',
   fontSize: '12px',
   fontStyle: 'italic',
   padding: '6px 6px 6px 0px',
  },
  termsWrapper: {
   display: 'flex',
   alignItems: 'flex-start',
  },
  logo: {
   width: '39px',
   height: '37px',
   display: 'block',
   background: '#3cba68',
   margin: '0 auto',
   position: 'relative',
   top: '13px',
  },
  link: {
   cursor: 'pointer',
   color: 'blue',
  },
  mail: {
   width: '60px',
  },
  passwordContainer: {
   bottom: '20px !important',
  },
 }
 // eslint-disable-next-line no-unused-vars
 const [open, setOpen] = React.useState(true)
 const [loading, setLoading] = React.useState(false)
 const [token, setToken] = React.useState(undefined)
 const [request, setRequest] = React.useState(-1)
 const [passwordChecker, setPasswordChecker] = React.useState(false)
 const [error, setError] = React.useState({
  text: '', type: undefined,
 })
 const [state, setState] = React.useState({
  values: {
   email: '',
  },
  errors: {
   error_email: false,
  },
 })
 const [password, setPassword] = React.useState({
  values: {
   new_password: '',
   password_confirmation: '',
  },
  errors: {
   error_new_password: false,
   error_password_confirmation: false,
  },
 })
 const [setForgotPassword, { data: forgotPasswordData, error: forgotPasswordError }] = useMutation(FORGOT_PASSWORD, {
  errorPolicy: 'all',
 })

 const [setResetPassword, { data: resetPasswordData, error: resetPasswordError }] = useMutation(RESET_PASSWORD, {
  errorPolicy: 'all',
 })

 useEffect(() => {
  const params = props?.location ? new URLSearchParams(props?.location?.search) : undefined
  if (params?.get('token')) {
   setToken(params?.get('token'))
   setRequest(3)
  } else {
   setRequest(1)
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

 useEffect(() => {
  if (forgotPasswordData) {
   setOpen(false)
   setLoading(false)
   setRequest(2)
  }
  if (forgotPasswordError) {
   if (forgotPasswordError?.message === '404: Not Found') {
    setError({
     text: 'Correo inválido. Por favor, inténtalo nuevamente.', type: 'error',
    })
    setOpen(true)
    setLoading(false)
   }
  }
 }, [forgotPasswordData, forgotPasswordError])

 useEffect(() => {
  if (resetPasswordData) {
   setOpen(false)
   setLoading(false)
   navigate(
    '/login',
    {
     state: {
      text: 'Tu contraseña se ha actualizado satisfactoriamente', type: 'success',
     },
    },
   )
  }
  if (resetPasswordError) {
   if (resetPasswordError?.message === '404: Not Found') {
    setError({
     text: 'Ha ocurrido un error. Por favor, inténtalo nuevamente.', type: 'error',
    })
    setOpen(true)
    setLoading(false)
   }
  }
 }, [resetPasswordData, resetPasswordError])

 const updateInput = (e, context) => {
  const tempState = (context === 'new_password' || context === 'password') ? password : state
  tempState.values[e.target.name] = e.target.value
  tempState.errors[`error_${e.target.name}`] = false
  if (context === 'new_password' || context === 'password') {
   setPassword({
    ...tempState,
   })
  } else {
   setState({
    ...tempState,
   })
  }
 }

 const getEmailConfirmation = (event) => {
  event.preventDefault()
  let validateError = null
  let errorsToFix = false
  // eslint-disable-next-line array-callback-return
  Object.entries(state.values).map(([keys, values]) => {
   validateError = validations.validateField({
    [keys]: values,
   })
   const tempState = state
   tempState.errors[`error_${keys}`] = validateError?.error_email
   if (validateError?.error_email === true) {
    errorsToFix = true
   }
   setState({
    ...tempState,
   })
  })
  if (errorsToFix === false) {
   setLoading(true)
   setForgotPassword({
    variables: {
     email: state?.values?.email,
    },
   })
  }
 }

 const changePassword = (event) => {
  event.preventDefault()
  let validateError = null
  let errorsToFix = false
  // eslint-disable-next-line array-callback-return
  Object.entries(password.values).map(([keys, values]) => {
   if (keys === 'password_confirmation') {
    validateError = validations.validateField({
     [keys]: {
      password: password.values.new_password, confirmation: values,
     },
    })
   } else {
    validateError = validations.validateField({
     [keys]: values,
    })
   }
   const tempState = password
   if (validateError?.error_new_password) {
    tempState.errors.error_new_password = validateError?.error_new_password
   } else if (validateError?.error_password_confirmation) {
    tempState.errors.error_password_confirmation = validateError?.error_password_confirmation
   }
   setPassword({
    ...tempState,
   })
   if (validateError?.error_new_password === true || validateError?.error_password_confirmation === true) {
    errorsToFix = true
   }
  })
  if (errorsToFix === false) {
   setLoading(true)
   setResetPassword({
    variables: {
     password: password?.values?.new_password,
     passwordConfirmation: password?.values?.password_confirmation,
     token,
    },
   })
  }
 }

 return (
  <>
   {/* Layout con mensaje a la izquierda y formulario a la derecha */}
   <Grid sx={styles.root} container justifyContent="center" alignItems="center">
    <Grid item xs={11} sm={8} md={6}>
     <Box sx={styles.titleContainer}>
      <Typography
       variant="h1"
       color="primary.main"
       display="inline"
      >
        Ingresa a tu cuenta en Cumplo, 
      </Typography>
      <Typography
       variant="h1"
       color="primary.dark"
       display="inline"
      >{process.env.GATSBY_REACT_APP_REGION === 'es-MX' ? ` la red de financiamiento ` : ` la red de financiamiento colaborativo `}
      </Typography>
      <Typography
       variant="h1"
       color="primary.main"
       display="inline"
      >
        con mayor impacto en Latinoamérica.
      </Typography>
     </Box>
    </Grid>

    {/* Formulario vacío */}
    {request !== -1 && (
     <Grid item xs={11} sm={9} md={4}>
      <Box sx={styles.cumploIcon}>
       <Logo variant={"isotype"} size={40} color={theme.palette.primary.main} />
      </Box>
      <Card sx={styles.registerCard}>
       <CardContent>
        {error.text !== '' && (
         <Collapse in={state.message.text !== ''}>
          <Alert
           style={{
            alignItems: 'center',
           }}
           severity={error.type}
           action={(
            <IconButton
             aria-label="close"
             size="small"
             onClick={() => {
              setOpen(false)
             }}
            >
             <Icon name="close" color={theme.palette.secondary.main} height="24px" />
            </IconButton>
           )}
          >
           {error.text}
          </Alert>
         </Collapse>
        )}




        {/* Formulario para ingresar el correo electrónico para cambiar la contraseña (es 1) */}
        {request === 1 && (
         <Box pt={4}>
          <form noValidate>
           <Grid container>
            <Grid item xs={12}>
             <Typography variant="subtitle1" color="primary.dark" align="center">¿Olvidaste tu contraseña?</Typography>
             <Typography align="center">Ingresa tu correo electrónico:</Typography>
             <Box paddingBottom={3} />
             <Grid container>
              <Grid item xs={12}>
               <TextField 
                type="email" 
                name="email" 
                id="standard-basic" 
                label="Correo electrónico" 
                autoFocus
                fullWidth
                value={state.values.email} 
                error={state.errors.error_email !== false} 
                onChange={(e) => updateInput(e, 'state')} 
                InputLabelProps={{
                 style: { color: theme.palette.text.primary },
                }}
               />
               <FormHelperText id="component-error-text" sx={{ textAlign: "center", color: theme.palette.error.main}}>
                {state.errors.error_email
                                && 'El correo ingresado no es válido'}
               </FormHelperText>
              </Grid>
             </Grid>
             <Grid container justify="center">
              <Grid                 
               item
               xs={12}
               style={{
                textAlign: "center"
               }}
              >
               <Box paddingBottom={3} />
               <Button
                loading={loading}
                variant="contained"
                type="submit"
                action={(event) => getEmailConfirmation(event)}
               >
                    Continuar
               </Button>
              </Grid>
             </Grid>
            </Grid>
           </Grid>
          </form>
         </Box>
        )}












        {/* Mensaje de correo enviado para recuperar la contraseña, resetear, reiniciar (es 2) */}
        {request === 2 && (
         <>
          <Box pt={3} />
          <Grid container>
           <Grid item xs={12}>
            <Typography variant="subtitle1" color="primary.dark" align="center">¡Revisa tu correo!</Typography>
            <Box paddingBottom={3} />
            <Typography align="center">Enviamos un correo electrónico a {state.values.email} para restablecer tu contraseña.</Typography>
            <Box paddingBottom={3} />
           </Grid>
          </Grid>
         </>
        )}


        {/* Formulario para cambiar la contraseña, con el validador de contraseña (es 3) */}
        {request === 3 && (
         <Box pt={4}>
          <form noValidate>
           <Grid container>
            <Grid item xs={12}>
             <Grid container>
              <Grid item xs={12}>
               <Typography align="center">Introduce tu nueva contraseña</Typography>
               <Box paddingBottom={3} />
              </Grid>
             </Grid>
             <Grid container rowGap={3}>
              <Grid item xs={12}>
               <TextField 
                fullWidth 
                onFocus={() => setPasswordChecker(true)} 
                onBlur={() => setPasswordChecker(false)} 
                name="new_password" 
                type="password" 
                id="standard-basic" 
                label="Nueva contraseña" 
                value={password.values.new_password} 
                error={password.errors.error_new_password !== false} 
                onChange={(e) => updateInput(e, 'new_password')}
                InputLabelProps={{
                 style: { color: theme.palette.text.primary },
                }}
               />
                
               <PasswordCheckup password={password.values.new_password} isOpen={passwordChecker} />
               <FormHelperText id="component-error-text" sx={{ textAlign: "center", color: theme.palette.error.main}}>
                {password.errors.error_new_password
                                && 'La contraseña no es válida'}
               </FormHelperText>
              </Grid>
              <Grid item xs={12}>
               <TextField 
                fullWidth 
                name="password_confirmation"
                type="password" id="standard-basic" 
                label="Confirmar nueva contraseña" 
                value={password.values.password_confirmation} 
                error={password.errors.error_password_confirmation !== false} 
                onChange={(e) => updateInput(e, 'password')}
                InputLabelProps={{
                 style: { color: theme.palette.text.primary },
                }}
               />
               <FormHelperText id="component-error-text" sx={{ textAlign: "center", color: theme.palette.error.main}}>
                {password.errors.error_password_confirmation
                                && 'La contraseña no coincide con la ingresada'}
               </FormHelperText>
              </Grid>
             </Grid>
             <Box paddingBottom={3} />
             <Grid container>
              <Grid item xs={12} sx={{textAlign: "center"}}>
               <Button
                // disabled={state.errors}
                loading={loading}
                variant="contained"
                type="submit"
                action={(event) => changePassword(event)}
               >
                    Continuar
               </Button>

              </Grid>
             </Grid>
            </Grid>
           </Grid>
          </form>
         </Box>
        )}





        
       </CardContent>
      </Card>
     </Grid>
    )}
   </Grid>
  </>
 )
}

export default ForgotPassword
