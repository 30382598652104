import React from 'react'
import { Box, useTheme } from '@mui/material'
import { Icon } from "src/templates"

const PasswordCheckup = (props) => {

 const theme = useTheme()

 const styles = {
  passwordValidator: {
   width: '205px',
   height: 'auto',
   position: 'absolute',
   paddingTop: '10px',
   left: '39px',
   zIndex: 10,
   display: props?.isOpen === true ? 'block' : 'none',
   '& ul': {
    background: '#EDEDED',
    borderRadius: '6px',
    fontSize: '13px',
    listStyleType: 'none',
    padding: '10px',
    margin: '0',
    '& li': {
     display: 'flex',
     alignItems: 'center',
    },
   },
   '& ul li img': {
    height: '15px',
    display: 'inline-block',
    padding: '0px 5px',
    margin: '5px 0px',
   },
   '& ul li p': {
    display: 'inline-block',
    margin: '0px',
    fontSize: '12px',
    padding: '0px 5px',
    width: 'calc(100% - 25px)',
   },
   '&:before': {
    bottom: '110px',
    right: '47%',
    content: "' '",
    display: 'block',
    position: 'absolute',
    background: 'transparent',
    borderTop: '8px solid transparent',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid #EDEDED',
    borderBottom: '8px solid transparent',
    transform: 'rotate(90deg)',
   },
  },
  error: {
   color: theme.palette.error.main,
   paddingBottom: 2,
  },
  success: {
   color: theme.palette.success.main,
   paddingBottom: 2,
  },
  validatorTitle: {
   padding: '9px 18px 0px',
   fontSize: '12px',
  },
 }

 const { password } = props
 const criteriaOne = new RegExp(
  /^.*(?=.{8,}).*$/,
 )
 const criteriaTwo = new RegExp(
  /^.*(?=.*[a-z]).*$/,
 )
 const criteriaThree = new RegExp(
  /^.*(?=.*[A-Z]).*$/,
 )
 const criteriaFour = new RegExp(
  /^.*(?=.*[º!"·$%&/()=?¿¡'^*¨Ç;:_,.´ç`+@+#)(=._-]+).*$/,
 )
 const criteriaFive = new RegExp(
  /^.*(?=.*[0-9]).*$/,
 )
 return (
  <Box
   sx={styles.passwordValidator}
  >
   <Box component="ul">
    <Box component="li" sx={{color: criteriaOne.test(password) ? styles.success : styles.error}}>
     <Box>
      {criteriaOne.test(password) ? (
       <Icon width="16px" name="checkFill" color={theme.palette.primary.main}/>
      ):(
       <Icon width="16px" name="checkDark" color={theme.palette.error.main}/>
      )}
     </Box>
     <p>Al menos 8 caracteres</p>
    </Box>
    <Box component="li" sx={{color: criteriaTwo.test(password) ? styles.success : styles.error}}>
     
     <Box>
      {criteriaTwo.test(password) ? (
       <Icon width="16px" name="checkFill" color={theme.palette.primary.main}/>
      ):(
       <Icon width="16px" name="checkDark" color={theme.palette.error.main}/>
      )}
     </Box>
     <p>Una letra en minúsculas</p>
    </Box> 
    <Box component="li" sx={{color: criteriaThree.test(password) ? styles.success : styles.error}}>
     <Box>
      {criteriaThree.test(password) ? (
       <Icon width="16px" name="checkFill" color={theme.palette.primary.main}/>
      ):(
       <Icon width="16px" name="checkDark" color={theme.palette.error.main}/>
      )}
     </Box>
     <p>Una letra en mayúsculas</p>
    </Box> 
    <Box component="li" sx={{color: criteriaFour.test(password) ? styles.success : styles.error}}>
     <Box>
      {criteriaFour.test(password) ? (
       <Icon width="16px" name="checkFill" color={theme.palette.primary.main}/>
      ):(
       <Icon width="16px" name="checkDark" color={theme.palette.error.main}/>
      )}
     </Box>
     <p>Un caracter especial</p>
    </Box> 
    <Box component="li" sx={{color: criteriaFive.test(password) ? styles.success : styles.error}}>
     <Box>
      {criteriaFive.test(password) ? (
       <Icon width="16px" name="checkFill" color={theme.palette.primary.main} />
      ):(
       <Icon width="16px" name="checkDark" color={theme.palette.error.main}/>
      )}
     </Box>
     <p>Un dígito</p>
    </Box> 
   </Box>
  </Box>
 )
}

export default PasswordCheckup
